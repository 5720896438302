import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { ConversationsService } from "../shared/services/conversations/conversations.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-conversations",
  templateUrl: "./conversations.component.html",
  styleUrls: ["./conversations.component.scss"],
})
export class ConversationsComponent implements OnInit {
  groupedConversations: any = {};
  periods: string[] = ["today", "last7Days", "last30Days", "older"];
  hoveredConversationId: number | null = null;
  @Output() conversationSelected = new EventEmitter<number>();

  constructor(
    private conversationsService: ConversationsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.conversationsService.getConversations().subscribe(conversations => {
      this.groupConversations(conversations);
    });
  }

  groupConversations(conversations: any[]): void {
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    this.groupedConversations = {
      today: [],
      last7Days: [],
      last30Days: [],
      older: [],
    };

    conversations
      .filter(conversation => conversation.title && conversation.title.trim()) // Exclude empty titles
      .forEach(conversation => {
        const createdAt = new Date(conversation.created_at);
        const daysDiff = Math.floor(
          (today.getTime() - createdAt.getTime()) / oneDay,
        );

        if (daysDiff === 0) {
          this.groupedConversations.today.push(conversation);
        } else if (daysDiff <= 7) {
          this.groupedConversations.last7Days.push(conversation);
        } else if (daysDiff <= 30) {
          this.groupedConversations.last30Days.push(conversation);
        } else {
          this.groupedConversations.older.push(conversation);
        }
      });
  }

  deleteConversation(
    conversationId: number,
    period: string,
    event: MouseEvent,
  ): void {
    event.stopPropagation();
    this.conversationsService.deleteConversation(conversationId).subscribe({
      next: () => {
        this.groupedConversations[period] = this.groupedConversations[
          period
        ].filter((c: any) => c?.id !== conversationId);
      },
    });
  }

  isLastMonth(date: Date, today: Date): boolean {
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const thisMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    return date >= lastMonth && date < thisMonth;
  }

  selectConversation(conversationId: number): void {
    this.router.navigate(["/chat", conversationId]);
  }

  isRecent(conversationTime: Date): boolean {
    const cutoffTime = new Date();
    cutoffTime.setHours(3, 0, 0, 0);
    return conversationTime >= cutoffTime;
  }

  onMouseEnter(conversationId: number): void {
    this.hoveredConversationId = conversationId;
  }

  onMouseLeave(): void {
    this.hoveredConversationId = null;
  }
}
