import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, take } from "rxjs/operators";
import { WebSocketService } from "../websocket/websocket.service";

@Injectable({
  providedIn: "root",
})
export class ChatService {
  private messageSubject = new BehaviorSubject<any>(null);
  public messages: Observable<any> = this.messageSubject.asObservable();

  constructor(private wsService: WebSocketService) {}

  createConversation(): Observable<any> {
    const message = { type: "create_conversation" };
    this.wsService.sendMessage(message);
    return this.wsService.messages.pipe(
      filter(msg => msg && msg.type === "conversation_created"),
      take(1),
    );
  }

  updateConversationTitle(conversationId: number, title: string): void {
    const message = {
      type: "update_conversation_title",
      title: title.slice(0, 99),
      conversation_id: conversationId,
    };
    this.wsService.sendMessage(message);
  }

  sendMessage(conversationId: number, messageContent: any): void {
    const message = { ...messageContent, conversation_id: conversationId };
    this.wsService.sendMessage(message);
  }

  getMessages(): Observable<any> {
    return this.wsService.messages;
  }

  closeWebSocketConnection(): void {
    this.wsService.close();
  }
}
