<div class="sankey-chart-container">
  <p *ngIf="!hasData" i18n="dashboard-sankey-graph:no-data-available">
    {{ "dashboard.sankey no data available" | translate }}
  </p>
  <google-chart
    #chart
    *ngIf="hasData"
    (ready)="onChartReady($event)"
    [title]="title | translate"
    [type]="type"
    [data]="graphData"
    [columnNames]="columnNames"
    [options]="options"
    [width]="width"
    [height]="height"
  >
  </google-chart>
</div>
