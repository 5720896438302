import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { IntegrationsComponent } from "./views/integrations/integrations.component";
import { LoginComponent } from "./views/login/login.component";
import { PasswordResetRequestComponent } from "./views/password-reset-request/password-reset-request.component";
import { PasswordResetConfirmComponent } from "./views/password-reset-confirm/password-reset-confirm.component";
import { RedirectComponent } from "./views/redirect/redirect.component";
import { TagsComponent } from "./views/tags/tags.component";
import { UserManagementComponent } from "./views/user-management/user-management.component";
import { ErrorPageComponent } from "./shared/components/error-page/error-page.component";
import { AuthGuard } from "./shared/guards/auth.guard";
import { WorkflowsComponent } from "./views/workflows/workflows.component";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { WorkflowCategoryCreateComponent } from "./views/workflows/workflow-category-create/workflow-category-create.component";
import { WorkflowCreateComponent } from "./views/workflows/workflow-create/workflow-create.component";
import { WorkflowsPerCategoryComponent } from "./views/workflows/workflows-per-category/workflows-per-category.component";
import { ChatComponent } from "./views/chat/chat.component";
import { KnowledgeComponent } from "./views/knowledge/knowledge.component";

const routes: Routes = [
  { path: "", component: LoginComponent },
  { path: "password-reset-request", component: PasswordResetRequestComponent },
  {
    path: "password-reset-confirm/:uid/:token",
    component: PasswordResetConfirmComponent,
  },
  { path: "redirect", component: RedirectComponent },
  {
    path: "dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "users",
    component: UserManagementComponent,
    canActivate: [AuthGuard],
  },
  { path: "tags", component: TagsComponent, canActivate: [AuthGuard] },
  {
    path: "integrations",
    component: IntegrationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflows",
    component: WorkflowsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflow/category/create",
    component: WorkflowCategoryCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflow/category/update",
    component: WorkflowCategoryCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflow/category/:id",
    component: WorkflowsPerCategoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflow/create",
    component: WorkflowCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "workflow/update",
    component: WorkflowCreateComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "chat",
    component: ChatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "chat/:conversationId",
    component: ChatComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "knowledge",
    component: KnowledgeComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "workflow/:id",
  //   component: WorkflowCreateComponent,
  //   canActivate: [AuthGuard],
  // },
  { path: "error", component: ErrorPageComponent, canActivate: [AuthGuard] },
  { path: "**", component: ErrorPageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: "reload",
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
