import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { catchError } from "rxjs/operators";
import { BucketService } from "../../services/bucket/bucket.service";
import { AuthenticationService } from "../../services/authentication/authentication.service";
import { STORAGE_KEYS } from "../../constants/localeStorageKeys";
import { GASPAR } from "../../constants/gaspar";
import {
  faTags,
  faUserFriends,
  faWarehouse,
} from "@fortawesome/free-solid-svg-icons";

export enum SidebarStatusEnum {
  CLOSED = "close",
  OPEN = "open",
}

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
})
export class SidebarComponent implements OnInit {
  @Input() isSidebarAvailable: boolean = false;

  GASPAR = GASPAR;
  usersIcon = faUserFriends;
  tagsIcon = faTags;
  dashboardIcon = faWarehouse;

  showSidebar: boolean = false;
  currentTab: string = "users";

  hasBucket: boolean = false;
  isEmailAuth: boolean = true;
  isChatEnabled: boolean = false;

  constructor(
    private router: Router,
    private bucketService: BucketService,
    private authenticationService: AuthenticationService,
  ) {}

  ngOnInit(): void {
    this.updateSidebarVisibility();
    this.checkBucketStatus();
    this.checkAuthType();
  }

  updateSidebarVisibility(): void {
    this.showSidebar =
      localStorage.getItem(STORAGE_KEYS.SIDEBAR) !== SidebarStatusEnum.CLOSED;
    if (!this.showSidebar) return;

    const path = window.location.pathname;
    this.setSidebarTabBasedOnPath(path);
  }

  checkBucketStatus(): void {
    if (!this.authenticationService.isAuthenticated) return;

    this.bucketService
      .checkIsActive()
      .pipe(
        catchError(() => {
          return of(false);
        }),
      )
      .subscribe((isActive: boolean) => {
        this.hasBucket = isActive;
      });
  }

  async checkAuthType(): Promise<void> {
    await this.authenticationService.getAuthDetails();
    this.isEmailAuth =
      this.authenticationService.authDetails &&
      this.authenticationService.authDetails.type === "email";
    this.isChatEnabled =
      this.authenticationService.authDetails &&
      this.authenticationService.authDetails.chat_enabled;
  }

  setSidebarTabBasedOnPath(path: string): void {
    if (path.includes("dashboard")) {
      this.sidebarValue("dashboard");
    } else if (path.includes("users")) {
      this.sidebarValue("users");
    } else if (path.includes("tags")) {
      this.sidebarValue("tags");
    } else if (path.includes("integrations")) {
      this.sidebarValue("integrations");
    } else if (path.includes("workflows") || path.includes("workflow")) {
      this.sidebarValue("workflows");
    } else if (path.includes("chat")) {
      this.sidebarValue("chat");
    } else if (path.includes("knowledge")) {
      this.sidebarValue("knowledge");
    }
  }

  sidebarValue = (value: string) => {
    this.currentTab = value;
  };

  onSidebarTabChange = (value: string) => {
    this.currentTab = value;
    this.router.navigateByUrl(value).then(() => {
      if (value === "chat") {
        window.location.reload();
      }
    });
  };
}
