import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { URLS } from "../../constants/urls";

interface PresignedPostData {
  url: string;
  fields: Record<string, string>;
}

interface ActiveResponse {
  active: boolean;
}

@Injectable({
  providedIn: "root",
})
export class BucketService {
  constructor(private http: HttpClient) {}

  checkIsActive(): Observable<boolean> {
    return this.http.get<ActiveResponse>(URLS.BUCKET_ACTIVE).pipe(
      map((response: ActiveResponse) => response.active),
      catchError(error => {
        if (error.status === 403) {
          return throwError(() => new Error("Forbidden"));
        }
        return throwError(() => error);
      }),
    );
  }

  getPresignedUrl(
    filename: string,
    metadata: Record<string, string>,
  ): Observable<PresignedPostData> {
    return this.http.post<PresignedPostData>(URLS.PRESIGNED_POST, {
      filename,
      metadata,
    });
  }

  async uploadFileToBucket(
    file: File,
    presignedPostData: PresignedPostData,
  ): Promise<Response> {
    const formData = new FormData();
    Object.entries(presignedPostData.fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append("file", file);
    return fetch(presignedPostData.url, {
      method: "POST",
      body: formData,
    });
  }
}
