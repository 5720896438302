import { Injectable } from "@angular/core";
import { Message } from "src/app/shared/components/messages/messages.component";
@Injectable({
  providedIn: "root",
})
export class MessagesService {
  messages: Message[] = [];

  add(message: Message) {
    // Add a message in the queue
    message.id = this.messages.length + 1;
    this.messages.push(message);

    // By default messages are closable.
    if (message.closable === undefined) {
      message.closable = true;
    }
    // Magic happens here. Auto remove if time has been specified.
    if (message.time) {
      setTimeout(() => {
        this.remove(message.id!);
      }, message.time);
    }
  }

  remove(id: number) {
    // Remove a message from the queue
    this.messages = this.messages.filter(x => x.id !== id);
  }
}
