import { Component, OnInit, OnDestroy } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ArticleService } from "src/app/shared/services/article/article.service";
import { BucketService } from "src/app/shared/services/bucket/bucket.service";
import { UserService } from "src/app/shared/services/user/user.service";
import { Article } from "src/app/shared/models/article";
import { DatePipe } from "@angular/common";
import { URLS } from "src/app/shared/constants/urls";
import { IntegrationService } from "src/app/shared/services/integration/integration.service";
import { NormalResponse } from "src/app/shared/models/common";
import { IntegrationInfo } from "src/app/shared/models/integration";

@Component({
  selector: "app-knowledge",
  templateUrl: "./knowledge.component.html",
  styleUrls: ["./knowledge.component.scss"],
  providers: [DatePipe],
})
export class KnowledgeComponent implements OnInit, OnDestroy {
  allArticles: Article[] = [];
  articles: Article[] = [];
  isLoading: boolean = false;
  syncLoading: boolean = false;
  individualLoading: boolean = false;
  searchQuery: string = "";
  currentView: string = "block";

  integration_info: IntegrationInfo | null = {} as IntegrationInfo;
  syncInProgress: boolean = false;
  private integrationCheckInterval: any;

  constructor(
    private articleService: ArticleService,
    private bucketService: BucketService,
    private userService: UserService,
    private integrationService: IntegrationService,
    private datePipe: DatePipe,
  ) {}

  ngOnInit(): void {
    this.loadArticles(true);
    this.loadIntegrationInfo();
  }

  ngOnDestroy(): void {
    if (this.integrationCheckInterval) {
      clearInterval(this.integrationCheckInterval);
    }
  }

  loadArticles(useMainLoadingIndicator: boolean = true): void {
    if (useMainLoadingIndicator) {
      this.isLoading = true;
    } else {
      this.individualLoading = true;
    }

    this.articleService.articleSubject.subscribe({
      next: (articles: Article[]) => {
        this.allArticles = articles.map(article => ({
          ...article,
          displayDate:
            this.datePipe.transform(article.created_at, "dd/MM/yy") || "",
        }));
        this.articles = this.allArticles;
        if (useMainLoadingIndicator) {
          this.isLoading = false;
        } else {
          this.individualLoading = false;
        }
      },
      error: (err: HttpErrorResponse) => {
        alert(`Failed to load articles: ${err.message}`);
        if (useMainLoadingIndicator) {
          this.isLoading = false;
        } else {
          this.individualLoading = false;
        }
      },
    });

    this.articleService.getArticles();
  }

  loadIntegrationInfo = () => {
    this.integrationService
      .getIntegration(URLS.INTEGRATION_LIST + "bucket/status/")
      .then((integration_info: IntegrationInfo | null) => {
        this.integration_info = integration_info;
        this.syncInProgress =
          this.integration_info?.show_manual_sync_knowledge_in_progress ||
          false;

        if (this.syncInProgress) {
          this.startIntegrationPolling();
        } else {
          this.stopIntegrationPolling();
        }
      });
  };

  startIntegrationPolling(): void {
    if (!this.integrationCheckInterval) {
      this.integrationCheckInterval = setInterval(() => {
        if (this.syncInProgress) {
          this.loadIntegrationInfo();
        } else {
          this.stopIntegrationPolling();
        }
      }, 15000);
    }
  }

  stopIntegrationPolling(): void {
    if (this.integrationCheckInterval) {
      clearInterval(this.integrationCheckInterval);
      this.integrationCheckInterval = null;
      alert("Knowledge Syncronization is completed!");
    }
  }

  openArticle(url: string): void {
    window.open(url, "_blank");
  }

  handleFileInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files || input.files.length === 0) {
      return;
    }
    const file = input.files[0];
    const maxFileSize = 5 * 1024 * 1024;

    if (file.size > maxFileSize) {
      alert("File size should not exceed 5MB");
      return;
    }

    const metadata = { created_by: this.userService.user.name };
    this.uploadFile(file, metadata);
  }

  uploadFile(file: File, metadata: Record<string, string>): void {
    this.individualLoading = true;
    this.bucketService.getPresignedUrl(file.name, metadata).subscribe({
      next: presignedPostData => {
        this.bucketService
          .uploadFileToBucket(file, presignedPostData)
          .then(response => {
            if (!response.ok) {
              alert("Failed to upload file");
            }
          })
          .finally(() => {
            this.individualLoading = false;
            window.location.reload();
          });
      },
      error: (error: HttpErrorResponse) => {
        alert(`Error getting presigned URL: ${error.message}`);
        this.individualLoading = false;
      },
    });
  }

  deleteArticle(filename: string): void {
    this.individualLoading = true;
    this.articleService.deleteArticle(filename).subscribe({
      next: () => {
        this.articles = this.articles.filter(
          article => article.filename !== filename,
        );
      },
      error: (error: HttpErrorResponse) => {
        alert(`Error deleting article: ${error.message}`);
      },
      complete: () => (this.individualLoading = false),
    });
  }

  getFileType(filename: string): string {
    const extension = filename.split(".").pop();
    return extension ? extension.toLowerCase() : "default";
  }

  toggleActions(index: number): void {
    this.articles.forEach((article, idx) => {
      if (idx === index) {
        article.showActions = !article.showActions;
      } else {
        article.showActions = false;
      }
    });
  }

  filterArticles(): void {
    if (!this.searchQuery) {
      this.articles = this.allArticles;
    } else {
      this.articles = this.allArticles.filter(article =>
        article.filename.toLowerCase().includes(this.searchQuery.toLowerCase()),
      );
    }
  }

  setView(viewType: string): void {
    this.currentView = viewType;
  }

  onSortChange(event: Event): void {
    const target = event.target as HTMLSelectElement;
    const value = target.value;
    if (value) {
      const [criteria, order] = value.split(",");
      this.sortArticles(criteria, order === "true");
    }
  }

  sortArticles(criteria: string, ascending: boolean): void {
    if (criteria === "filename") {
      this.articles = [...this.articles].sort((a, b) => {
        const diff = a.filename.localeCompare(b.filename);
        return ascending ? diff : -diff;
      });
    } else if (criteria === "date") {
      this.articles = [...this.articles].sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return ascending
          ? dateA.getTime() - dateB.getTime()
          : dateB.getTime() - dateA.getTime();
      });
    }
  }

  isSelected(viewType: string): boolean {
    return this.currentView === viewType;
  }

  onManualSyncKnowledge = () => {
    this.syncInProgress = true;
    this.startIntegrationPolling();

    this.integrationService
      .triggerManualSync(URLS.INTEGRATION_SYNC_KNOWLEDGE, "bucket")
      .then((res: NormalResponse) => {
        if (!res.ok) throw new Error(`Response is not ok: ${res}`);
      })
      .catch(() => {
        this.syncInProgress = false;
        this.stopIntegrationPolling();
      });
  };
}
