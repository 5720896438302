<div class="redirect-page-container" style="padding: 2rem">
  <div class="gaspar-card redirect-card">
    <img src="assets/images/login/login-logo.svg" alt="logo" />
    <p
      class="welcome-text"
      i18n="redirect:you will be redirected"
      *ngIf="loading"
    >
      Now you will be redirected<br />Please wait...
    </p>
    <div class="error-container" *ngIf="!loading && error">
      <img src="assets/images/login/auth-error.svg" alt="error" />
      <p class="welcome-text" i18n="redirect:please reach to your admin">
        {{ error }}<br />Please reach out to your admin to grant you access
      </p>
      <button
        mat-raised-button
        class="gaspar-primary-btn back-btn"
        (click)="onBack()"
        i18n="redirect:back to login"
      >
        Back to login
      </button>
    </div>
  </div>
</div>
