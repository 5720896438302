<div style="padding: 2rem">
  <!-- Title related -->
  <div class="title-bar">
    <span i18n="workflows:workflow title" class="gaspar-title">Workflows</span>
    <button mat-raised-button color="primary">
      <div (click)="onCreateWorkflowModal()">
        <img
          src="assets/images/workflow/plus.svg"
          alt="add"
          i18n="workflows:workflow create button"
        />
        Create
      </div>
    </button>
  </div>

  <div class="tabs-container">
    <button
      mat-flat-button
      i18n="workflows:active tab"
      [class.selected]="onSelectedTagChange('active')"
      (click)="onChangeTag('active')"
      class="tab-btn"
    >
      Active
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      [class.selected]="onSelectedTagChange('archived')"
      (click)="onChangeTag('archived')"
      i18n="workflows:archived tab"
      class="tab-btn"
    >
      Archived
    </button>
    <div class="right">
      <mat-form-field appearance="outline">
        <form [formGroup]="searchForm">
          <div class="inner">
            <input
              matInput
              type="search"
              formControlName="search"
              placeholder="Type here to search..."
            />
            <img src="assets/images/user-management/search_icon.svg" />
          </div>
        </form>
      </mat-form-field>
    </div>
  </div>

  <!-- Search workflows -->
  <div
    *ngIf="
      !isWorkflowsLoading && hasWorkflows && searchForm.controls.search.value
    "
  >
    <div class="line"></div>
    <div class="w-content-container">
      <div class="w-content">
        <div *ngFor="let workflow of workflows">
          <div class="box item">
            <div class="wrapper">
              <div class="header">
                <span class="created_at" i18n="workflows: workflow created"
                  >Created: {{ workflow.created_at | date }}</span
                >
                <div class="dots" [matMenuTriggerFor]="menu">
                  <img src="assets/images/dots.svg" />
                  <mat-menu #menu="matMenu" backdropClass="gaspar-menu">
                    <button
                      mat-menu-item
                      (click)="onWorkflowEditClicked(workflow)"
                      *ngIf="workflow.is_active"
                    >
                      <div class="item">Edit</div>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowDuplicateClicked(workflow)"
                      *ngIf="workflow.is_active"
                    >
                      <div class="item">Duplicate</div>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowInfoClicked(workflow)"
                    >
                      <div class="item">Info</div>
                      <app-modal [id]="workflowModalKey(workflow)">
                        <app-info-modal
                          [id]="workflowModalKey(workflow)"
                          header="Workflow Info"
                          [title]="workflow.title"
                          [description]="workflow.description"
                          [isWorkflow]="true"
                          [isActive]="workflow!.is_active"
                          [instancePk]="workflow.id"
                        ></app-info-modal>
                      </app-modal>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowToggleArchiveClicked(workflow)"
                    >
                      <div class="item">
                        {{ workflow.is_active ? "Archive" : "Unarchive" }}
                      </div>
                    </button>
                  </mat-menu>
                </div>
              </div>

              <span class="name">{{ workflow.title | trimText: 50 }}</span>
              <span class="description word-break">
                {{ workflow.description | trimText: 80 }}</span
              >
              <div class="button">
                <button
                  mat-raised-button
                  color="primary"
                  i18n="workflows: run workflow btn"
                  (click)="runWorkflowModal(workflow)"
                  *ngIf="workflow.is_active"
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="box create centered" (click)="navigateToWorkflowCreation()">
          <div class="image-alligned" i18n="workflows:workflow create">
            <img src="assets/images/workflow/workflow.svg" />
            Create a Workflow
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Uncategorized workflwos when in no search mode. -->
  <div
    class="w-content-container"
    *ngIf="!isWorkflowsLoading && !searchForm.controls.search.value"
  >
    <span
      class="title"
      *ngIf="!hasWorkflows && !hasCategories"
      i18n="workflows:no workflow or category found"
    >
      No workflow or category found.
    </span>
    <span
      class="title"
      *ngIf="hasCategories"
      i18n="workflows:all workflow categories"
    >
      All Categories
    </span>
    <div class="w-content">
      <div *ngFor="let category of categories">
        <div class="box item centered">
          <div class="dots-abs" [matMenuTriggerFor]="menu">
            <img src="assets/images/dots.svg" />
            <mat-menu #menu="matMenu" backdropClass="gaspar-menu">
              <button
                mat-menu-item
                (click)="onWorkflowCategoryEditClicked(category)"
                *ngIf="category.is_active"
              >
                <div class="item">Edit</div>
              </button>
              <button
                mat-menu-item
                (click)="onWorkflowCategoryInfoClicked(category)"
              >
                <div class="item">Info</div>
                <app-modal [id]="categoryModalKey(category)">
                  <app-info-modal
                    [id]="categoryModalKey(category)"
                    header="Category Info"
                    [title]="category.name"
                    [description]="category.description"
                    [isActive]="category!.is_active"
                    [isWorkflow]="false"
                    [instancePk]="category!.id"
                  ></app-info-modal>
                </app-modal>
              </button>
              <button
                mat-menu-item
                (click)="onWorkflowCategoryToggleArchiveClicked(category)"
              >
                <div class="item">
                  {{ category.is_active ? "Archive" : "Unarchive" }}
                </div>
              </button>
            </mat-menu>
          </div>

          <div
            class="box-items"
            [class.pointer]="hasCategoryWorkflows(category)"
            (click)="goToCategoryDetails(category)"
            i18n="workflows:workflow create category"
          >
            <span class="name"> {{ category.name | trimText: 100 }} </span>
            <span class="active-workflows"
              >{{ getActiveWorkflowsForCategory(category) }} Active Workflows
            </span>
          </div>
        </div>
      </div>

      <div
        class="box create centered margin-top: 30px;"
        (click)="navigateToWorkflowCategoryCreation()"
      >
        <div class="image-alligned" i18n="workflows:workflow create category">
          <img src="assets/images/workflow/workflow-category.svg" />
          Create a Category
        </div>
      </div>
      <div
        class="box create centered"
        *ngIf="!hasWorkflows"
        (click)="navigateToWorkflowCreation()"
      >
        <div class="image-alligned" i18n="workflows:workflow create">
          <img src="assets/images/workflow/workflow.svg" />
          Create a Workflow
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="
      !isWorkflowsLoading && hasWorkflows && !searchForm.controls.search.value
    "
  >
    <div class="line"></div>

    <div class="w-content-container">
      <span class="title" i18n="workflows:uncategorized workflows">
        Uncategorized Workflows
      </span>
      <div class="w-content">
        <div *ngFor="let workflow of uncategorizedWorkflows">
          <div class="box item">
            <div class="wrapper">
              <div class="header">
                <span class="created_at" i18n="workflows: workflow created"
                  >Created: {{ workflow.created_at | date }}</span
                >
                <div class="dots" [matMenuTriggerFor]="menu">
                  <img src="assets/images/dots.svg" />
                  <mat-menu #menu="matMenu" backdropClass="gaspar-menu">
                    <button
                      mat-menu-item
                      (click)="onWorkflowEditClicked(workflow)"
                      *ngIf="workflow.is_active"
                    >
                      <div class="item">Edit</div>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowDuplicateClicked(workflow)"
                      *ngIf="workflow.is_active"
                    >
                      <div class="item">Duplicate</div>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowInfoClicked(workflow)"
                    >
                      <div class="item">Info</div>
                      <app-modal [id]="workflowModalKey(workflow)">
                        <app-info-modal
                          [id]="workflowModalKey(workflow)"
                          header="Workflow Info"
                          [title]="workflow.title"
                          [description]="workflow.description"
                          [isWorkflow]="true"
                          [isActive]="workflow!.is_active"
                          [instancePk]="workflow.id"
                        ></app-info-modal>
                      </app-modal>
                    </button>
                    <button
                      mat-menu-item
                      (click)="onWorkflowToggleArchiveClicked(workflow)"
                    >
                      <div class="item">
                        {{ workflow.is_active ? "Archive" : "Unarchive" }}
                      </div>
                    </button>
                  </mat-menu>
                </div>
              </div>

              <span class="name">{{ workflow.title | trimText: 50 }}</span>
              <span class="description word-break">
                {{ workflow.description | trimText: 80 }}</span
              >
              <div class="button">
                <button
                  mat-raised-button
                  color="primary"
                  i18n="workflows: run workflow btn"
                  (click)="runWorkflowModal(workflow)"
                  *ngIf="workflow.is_active"
                >
                  Run
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="box create centered" (click)="navigateToWorkflowCreation()">
          <div class="image-alligned" i18n="workflows:workflow create">
            <img src="assets/images/workflow/workflow.svg" />
            Create a Workflow
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
