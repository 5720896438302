import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { WebSocketSubject, webSocket } from "rxjs/webSocket";
import { URLS } from "../../constants/urls";

@Injectable({
  providedIn: "root",
})
export class WebSocketService {
  private socket$: WebSocketSubject<any>;
  private messageSubject = new BehaviorSubject<any>(null);
  public messages: Observable<any> = this.messageSubject.asObservable();
  private reconnectAttempts = 0;
  private readonly MAX_RECONNECT_ATTEMPTS = 3;

  constructor() {
    this.connect();
  }

  private get token(): string | null {
    return localStorage.getItem("token");
  }

  private connect(): void {
    const wsUrl = `${URLS.CHAT_WS}?token=${this.token}`;
    this.socket$ = webSocket(wsUrl);
    this.socket$.subscribe(
      (message: any) => {
        if (message.error) {
          this.handleWebSocketError(message);
        } else {
          this.messageSubject.next(message);
        }
      },
      (error: any) => this.handleWebSocketError(error),
      () => {
        this.reconnect();
      },
    );
  }

  private reconnect(): void {
    if (this.reconnectAttempts < this.MAX_RECONNECT_ATTEMPTS) {
      this.reconnectAttempts++;
      setTimeout(() => this.connect(), 2000 * this.reconnectAttempts);
    } else {
      this.messageSubject.next({
        error: true,
        message: "Unable to reconnect to the WebSocket server.",
      });
    }
  }

  private handleWebSocketError(message: any): void {
    this.messageSubject.next(message);
    this.reconnect();
  }

  sendMessage(message: any): void {
    if (this.socket$) {
      this.socket$.next(message);
    }
  }

  close(): void {
    this.socket$?.complete();
    this.reconnectAttempts = 0;
    this.messageSubject.next(null);
  }
}
