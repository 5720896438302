<div class="box">
  <div class="title-container">
    <div class="title" i18n="dashboard: conversations box">
      {{ title | translate }}
    </div>
    <img [src]="image" [alt]="title | translate" />
  </div>
  <div class="line"></div>
  <div class="content" [style.color]="color">{{ totalNumber }}</div>
</div>
