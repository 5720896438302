<div *ngIf="groupedConversations" class="conversation-items">
  <ng-container *ngFor="let period of periods">
    <div
      *ngIf="groupedConversations[period].length > 0"
      class="conversation-group"
    >
      <h3 class="conversation-header">{{ period | titlecase }}</h3>
      <ul>
        <li
          *ngFor="let conversation of groupedConversations[period]"
          class="conversation-item"
          (mouseenter)="onMouseEnter(conversation.id)"
          (mouseleave)="onMouseLeave()"
        >
          <span (click)="selectConversation(conversation.id)">
            {{ conversation.title | slice: 0:15 }}
            {{ conversation.title.length > 15 ? "..." : "" }}
          </span>
          <img
            src="assets/images/knowledge/delete.svg"
            alt="Delete"
            class="delete-icon"
            [ngClass]="{ visible: hoveredConversationId === conversation.id }"
            (click)="deleteConversation(conversation.id, period, $event)"
          />
        </li>
      </ul>
    </div>
  </ng-container>
</div>
