<div
  class="knowledge-container"
  [class.loading-cursor]="individualLoading"
  style="padding: 2rem"
>
  <div>
    <h1>{{ "knowledge.title" | translate }}</h1>
    <p class="file-info">
      Supported file types: .txt, .docx, .pdf. Max file size: 5MB.
    </p>
  </div>

  <div class="upload-toolbar">
    <div class="view-switch">
      <select (change)="onSortChange($event)" class="sort-dropdown">
        <option value="">
          {{ "knowledge.sort select placeholder" | translate }}
        </option>
        <option value="filename,true">
          {{ "knowledge.filename ascending" | translate }}
        </option>
        <option value="filename,false">
          {{ "knowledge.filename descending" | translate }}
        </option>
        <option value="date,true">
          {{ "knowledge.date ascending" | translate }}
        </option>
        <option value="date,false">
          {{ "knowledge.date descending" | translate }}
        </option>
      </select>
      <button
        (click)="setView('block')"
        [ngClass]="{ selected: isSelected('block') }"
        class="view-button"
      >
        <mat-icon>view_module</mat-icon>
      </button>
      <button
        (click)="setView('line')"
        [ngClass]="{ selected: isSelected('line') }"
        class="view-button"
      >
        <mat-icon>view_list</mat-icon>
      </button>
    </div>

    <div class="action-buttons-container">
      <ng-container *ngIf="individualLoading">
        <mat-spinner diameter="20"></mat-spinner>
      </ng-container>
      <input
        type="file"
        (change)="handleFileInput($event)"
        hidden
        #fileInput
        accept=".pdf,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
      <button
        class="sync"
        (click)="onManualSyncKnowledge()"
        [disabled]="syncInProgress"
      >
        {{
          (syncInProgress
            ? "knowledge.sync in progress"
            : "knowledge.sync knowledge button"
          ) | translate
        }}
      </button>
      <button class="upload" (click)="fileInput.click()" [disabled]="isLoading">
        {{ "knowledge.upload file button" | translate }}
        <img
          src="assets/images/knowledge/plus.svg"
          alt="Plus Icon"
          style="
            vertical-align: middle;
            margin-left: 5px;
            width: 20px;
            height: auto;
          "
        />
      </button>
    </div>
  </div>

  <input
    type="text"
    [(ngModel)]="searchQuery"
    class="search"
    placeholder="{{ 'knowledge.search placeholder' | translate }}"
    (input)="filterArticles()"
  />

  <div
    class="article-list"
    [ngClass]="{
      'line-view': currentView === 'line',
      'block-view': currentView === 'block'
    }"
  >
    <div *ngIf="isLoading" class="loading-overlay">
      <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="articles.length === 0 && !isLoading">
      {{ "knowledge.no articles available" | translate }}
    </div>
    <div
      *ngFor="let article of articles; let i = index"
      class="article-card"
      (click)="openArticle(article.url)"
    >
      <div class="article-header">
        <img
          *ngIf="getFileType(article.filename) as fileType"
          [src]="'assets/images/knowledge/' + fileType + '.svg'"
          [alt]="fileType"
          class="file-icon"
        />
        <div class="filename-and-options">
          <div
            class="article-filename"
            *ngIf="currentView === 'block'"
            title="{{ article.filename }}"
          >
            {{ article.filename | slice: 0:15
            }}{{ article.filename.length > 15 ? "..." : "" }}
          </div>
          <div
            class="article-filename"
            *ngIf="currentView === 'line'"
            title="{{ article.filename }}"
          >
            {{ article.filename | slice: 0:16
            }}{{ article.filename.length > 16 ? "..." : "" }}
          </div>
          <div class="article-details" *ngIf="currentView === 'line'">
            {{ article.metadata.created_by }}
          </div>
          <div class="article-details" *ngIf="currentView === 'line'">
            {{ article.created_at }}
          </div>
          <button
            class="options-button"
            (click)="toggleActions(i); $event.stopPropagation()"
          >
            &#x22EE;
          </button>
          <div class="actions-dropdown" *ngIf="article.showActions">
            <button
              (click)="
                deleteArticle(article.filename); $event.stopPropagation()
              "
            >
              <img
                src="assets/images/knowledge/delete.svg"
                alt="Delete Icon"
                style="width: 13px; height: auto"
              />
              {{ "knowledge.delete button" | translate }}
            </button>
          </div>
        </div>
      </div>
      <p class="article-content" *ngIf="currentView === 'block'">
        {{ article.content | slice: 0:100
        }}{{ article.content.length > 100 ? "..." : "" }}
      </p>
      <div class="article-footer" *ngIf="currentView === 'block'">
        {{ article.metadata.created_by }} {{ article.displayDate }}
      </div>
    </div>
  </div>
</div>
