<form class="gaspar-form" (ngSubmit)="onSubmit()" [formGroup]="tagTypeForm">
  <mat-dialog-content>
    <mat-dialog-header>
      <div i18n="tag-type-modal:Create tag type title" class="title">
        {{
          data.isEditAction
            ? ("tag-type modal.Edit tag type title" | translate)
            : ("tag-type modal.Create tag type title" | translate)
        }}
      </div>
      <hr />
    </mat-dialog-header>
    <div class="image-container">
      <img src="assets/images/tag-icon.svg" alt="tag" />
    </div>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label i18n="tag-type-modal:tag type name">
        {{ "tag-type modal.tag type name" | translate }}
      </mat-label>
      <input matInput formControlName="name" />
      <mat-error *ngIf="this.tagTypeForm.controls.name.invalid">
        {{ getErrorMessage("name") }}
      </mat-error>
    </mat-form-field>
    <mat-form-field class="w-100" appearance="fill">
      <mat-label i18n="tag-type-modal:tag type description">
        {{ "tag-type modal.tag type description" | translate }}
      </mat-label>
      <input matInput formControlName="description" />
      <mat-error *ngIf="this.tagTypeForm.controls.description.invalid">
        {{ getErrorMessage("description") }}
      </mat-error>
    </mat-form-field>
    <mat-error *ngIf="this.tagTypeForm.invalid">
      {{ getErrorMessage(null) }}
    </mat-error>
    <div class="properties-section">
      <div
        class="color-container"
        [cpPosition]="'bottom'"
        [cpPositionOffset]="'50%'"
        [(colorPicker)]="color"
      >
        <p>{{ color }}</p>
        <div class="color" [style.background]="color"></div>
      </div>
      <div class="checkbox-container">
        <section class="checkbox-item w-100">
          <mat-checkbox
            i18n="tag-type-modal:tag is unique"
            formControlName="unique"
          >
            {{ "tag-type modal.tag is unique" | translate }}
          </mat-checkbox>
          <mat-error *ngIf="tagTypeForm.controls.unique.invalid">
            {{ getErrorMessage("unique") }}
          </mat-error>
        </section>
        <section class="checkbox-item w-100">
          <mat-checkbox
            i18n="tag-type-modal:tag is selectable"
            formControlName="userSelectable"
          >
            {{ "tag-type modal.tag is selectable" | translate }}
          </mat-checkbox>
          <mat-error *ngIf="this.tagTypeForm.controls.userSelectable.invalid">
            {{ getErrorMessage("userSelectable") }}
          </mat-error>
        </section>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="actions">
    <a mat-button (click)="onCancel()" i18n="tag-type-modal:Cancel button">
      {{ "tag-type modal.Cancel button" | translate }}
    </a>
    <button
      mat-raised-button
      color="primary"
      i18n="tag-type-modal:Save button"
      [class.spinner]="isLoading"
      [disabled]="isLoading"
    >
      {{ "tag-type modal.Save button" | translate }}
    </button>
  </mat-dialog-actions>
</form>
