<div class="column-chart-container">
  <span *ngIf="graphData.length === 0">{{
    "dashboard.no data available" | translate
  }}</span>
  <google-chart
    *ngIf="graphData.length > 0"
    #columnChart
    (ready)="onChartReady($event)"
    [title]="title | translate"
    [type]="type"
    [data]="graphData"
    [options]="options"
    [width]="width"
    [height]="height"
    [columns]="columnNames"
  >
  </google-chart>
</div>
