import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthenticationService } from "src/app/shared/services/authentication/authentication.service";

@Component({
  selector: "app-password-reset-confirm",
  templateUrl: "./password-reset-confirm.component.html",
  styleUrls: ["./password-reset-confirm.component.scss"],
})
export class PasswordResetConfirmComponent implements OnInit {
  newPassword: string = "";
  confirmNewPassword: string = "";
  error: string | null = null;

  constructor(
    private authService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // Optional: Initialization logic here
  }

  resetPassword() {
    const uid = this.route.snapshot.paramMap.get("uid");
    const token = this.route.snapshot.paramMap.get("token");

    if (!uid || !token) {
      this.error = "Invalid or expired password reset link.";
      return;
    }

    if (this.newPassword !== this.confirmNewPassword) {
      this.error = "Passwords do not match.";
      return;
    }

    this.authService
      .resetPassword(uid, token, this.newPassword)
      .then(() => {
        alert("Password has been successfully reset.");
        this.router.navigateByUrl("");
      })
      .catch(() => {
        this.error = "Failed to reset password. Please try again.";
      });
  }
}
