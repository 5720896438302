import {
  CUSTOM_ELEMENTS_SCHEMA,
  ErrorHandler,
  Injectable,
  NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDividerModule } from "@angular/material/divider";
import { MatButtonModule } from "@angular/material/button";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatIconModule } from "@angular/material/icon";
import { NavbarComponent } from "./shared/components/navbar/navbar.component";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { LoginComponent } from "./views/login/login.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { TokenInterceptor } from "./shared/services/authentication/token.interceptor";
import { RedirectComponent } from "./views/redirect/redirect.component";
import { UserManagementComponent } from "./views/user-management/user-management.component";
import { MatSortModule } from "@angular/material/sort";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TagComponent } from "./shared/components/tag/tag.component";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { TagsComponent } from "./views/tags/tags.component";
import { CapitalizePipe } from "./shared/pipes/capitalize/capitalize.pipe";
import { SlideToggleIconComponent } from "./shared/components/slide-toggle-icon/slide-toggle-icon.component";
import { ErrorStateMatcher, MatRippleModule } from "@angular/material/core";
import { IntegrationsComponent } from "./views/integrations/integrations.component";
import { IntegrationComponent } from "./views/integrations/components/integration/integration.component";
import { MarkdownModule } from "ngx-markdown";
import {
  UntypedFormControl,
  FormGroupDirective,
  FormsModule,
  NgForm,
} from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from "@angular/material/card";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatDialogModule } from "@angular/material/dialog";
import { ReactiveFormsModule } from "@angular/forms";
import { MatMenuModule } from "@angular/material/menu";
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_LOCALE } from "@angular/material/core";
import { FormComponent } from "./shared/components/form/form.component";
import { EditExpertiseModalComponent } from "./views/user-management/components/edit-expertise-modal/edit-expertise-modal.component";
import { MdePopoverModule } from "@niewicz/mde-popover";
import { TagTypeModalComponent } from "./views/tags/components/tag-type-modal/tag-type-modal.component";
import { ColorPickerModule } from "ngx-color-picker";
import { TagModalComponent } from "./views/tags/components/tag-modal/tag-modal.component";
import { DeleteModalComponent } from "./shared/components/delete-modal/delete-modal.component";
import { ErrorPageComponent } from "./shared/components/error-page/error-page.component";
import { ErrorInterceptor } from "./shared/services/error-interceptor.interceptor";
import { WorkflowsComponent } from "./views/workflows/workflows.component";
import * as Sentry from "@sentry/angular";
import { DashboardComponent } from "./views/dashboard/dashboard.component";
import { BoxComponent } from "./views/dashboard/components/box/box.component";
import { GoogleChartsModule } from "angular-google-charts";

// MOMENT JS CONFIGURATION
import * as moment from "moment-timezone";
import { SankeyGraphComponent } from "./views/dashboard/components/sankey-graph/sankey-graph.component";
import { TopLevelStatisticsComponent } from "./views/dashboard/components/top-level-statistics/top-level-statistics.component";
import { ColumnWorkersChartComponent } from "./views/dashboard/components/column-workers-chart/column-workers-chart.component";
import { ColumnWorkflowsChartComponent } from "./views/dashboard/components/column-workflows-chart/column-workflows-chart.component";
import { WorkflowCategoryCreateComponent } from "./views/workflows/workflow-category-create/workflow-category-create.component";
import { WorkflowCreateModalComponent } from "./views/workflows/workflow-create-modal/workflow-create-modal.component";
import { TrimTextPipe } from "./shared/pipes/trim-text/trim-text.pipe";
import { OverlayComponent } from "./shared/components/overlay/overlay.component";
import { InfoModalComponent } from "./views/workflows/components/info-modal/info-modal.component";
import { ModalComponent } from "./shared/components/modal/modal.component";
import { WorkflowCreateComponent } from "./views/workflows/workflow-create/workflow-create.component";
import { StatusBarComponent } from "./views/workflows/components/status-bar/status-bar.component";
import { WorkflowCreateStepComponent } from "./views/workflows/workflow-steps/workflow-create-step/workflow-create-step.component";
import { WorkflowTypeStepComponent } from "./views/workflows/workflow-steps/workflow-type-step/workflow-type-step.component";
import { WorkflowIntegrationsStepComponent } from "./views/workflows/workflow-steps/workflow-integrations-step/workflow-integrations-step.component";
import { IntegrationsModalComponent } from "./views/workflows/components/integrations-modal/integrations-modal.component";
import { WorkflowKickOffStepComponent } from "./views/workflows/workflow-steps/workflow-kick-off-step/workflow-kick-off-step.component";
import { WorkflowReviewStepComponent } from "./views/workflows/workflow-steps/workflow-review-step/workflow-review-step.component";
import { RunWorkflowModalComponent } from "./views/workflows/components/run-workflow-modal/run-workflow-modal.component";
import { WorkflowsPerCategoryComponent } from "./views/workflows/workflows-per-category/workflows-per-category.component";
import { MissedOpportunityChartComponent } from "./views/dashboard/components/missed-opportunity-chart/missed-opportunity-chart.component";
import { PolusQueryTableComponent } from "./views/dashboard/components/polus-query-table/polus-query-table.component";
import { MessagesComponent } from "./shared/components/messages/messages.component";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { ChatComponent } from "./views/chat/chat.component";
import { ConversationsComponent } from "./conversations/conversations.component";
import { KnowledgeComponent } from "./views/knowledge/knowledge.component";
import { PasswordResetRequestComponent } from "./views/password-reset-request/password-reset-request.component";
import { PasswordResetConfirmComponent } from "./views/password-reset-confirm/password-reset-confirm.component";

moment.tz.setDefault("Etc/UTC");

// Forms related
/** Error when invalid control is dirty, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(
    control: UntypedFormControl | null,
    form: FormGroupDirective | NgForm | null,
  ): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || isSubmitted));
  }
}

// Function for setting up the TranslateLoader
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

// Sentry related
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}
  handleError(error: any) {
    Sentry.captureException(error.originalError || error);
  }
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    NavbarComponent,
    LoginComponent,
    RedirectComponent,
    UserManagementComponent,
    TagComponent,
    TagsComponent,
    CapitalizePipe,
    SlideToggleIconComponent,
    IntegrationsComponent,
    IntegrationComponent,
    FormComponent,
    EditExpertiseModalComponent,
    TagTypeModalComponent,
    TagModalComponent,
    DeleteModalComponent,
    ErrorPageComponent,
    WorkflowsComponent,
    DashboardComponent,
    BoxComponent,
    SankeyGraphComponent,
    TopLevelStatisticsComponent,
    ColumnWorkersChartComponent,
    ColumnWorkflowsChartComponent,
    WorkflowCategoryCreateComponent,
    WorkflowCreateModalComponent,
    TrimTextPipe,
    OverlayComponent,
    InfoModalComponent,
    ModalComponent,
    WorkflowCreateComponent,
    StatusBarComponent,
    WorkflowCreateStepComponent,
    WorkflowTypeStepComponent,
    WorkflowIntegrationsStepComponent,
    IntegrationsModalComponent,
    WorkflowKickOffStepComponent,
    WorkflowReviewStepComponent,
    RunWorkflowModalComponent,
    WorkflowsPerCategoryComponent,
    MissedOpportunityChartComponent,
    PolusQueryTableComponent,
    MessagesComponent,
    ChatComponent,
    ConversationsComponent,
    KnowledgeComponent,
    PasswordResetRequestComponent,
    PasswordResetConfirmComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDividerModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    FontAwesomeModule,
    NgxSpinnerModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatRippleModule,
    MatExpansionModule,
    FormsModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    NgxMatSelectSearchModule,
    MatCheckboxModule,
    MatInputModule,
    MatDatepickerModule,
    MatMomentDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MdePopoverModule,
    ColorPickerModule,
    MatMenuModule,
    MatCardModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    GoogleChartsModule,
  ],
  providers: [
    // {
    //   provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    //   useValue: { floatLabel: "always" },
    // },
    { provide: ErrorStateMatcher, useClass: MyErrorStateMatcher },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
