<div class="tags-container" style="padding: 2rem">
  <span i18n="tags:tags title" class="gaspar-title">Tags</span>
  <div class="tabs-container">
    <button
      mat-flat-button
      i18n="tags:active tab"
      class="tab-btn"
      [class.selected]="onSelectedTagChange('active')"
      (click)="onChangeTag('active')"
    >
      Active
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="tags:system tab"
      class="tab-btn"
      [class.selected]="onSelectedTagChange('system')"
      (click)="onChangeTag('system')"
    >
      System
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="tags:by customers tab"
      class="tab-btn"
      [class.selected]="onSelectedTagChange('by-customers')"
      (click)="onChangeTag('by-customers')"
    >
      Added by Customers
    </button>
    <div class="grey-dot"></div>
    <button
      mat-flat-button
      i18n="tags:archived tab"
      class="tab-btn"
      [class.selected]="onSelectedTagChange('archived')"
      (click)="onChangeTag('archived')"
    >
      Archived
    </button>
  </div>

  <div
    class="add-tag-type-container pointer"
    (click)="onNewTagTypeButtonClicked()"
  >
    <span class="gaspar-span" i18n="tags: add tag type button"
      >Add tag type</span
    >
  </div>
  <div class="tags-type-container" *ngFor="let tt of tagTypes">
    <div class="tag-type-header flex row items-center w-100 text-break">
      <span i18n="tags:tags title" class="gaspar-secondary-title">
        {{ tt.name | capitalize }}
      </span>
      <img
        *ngIf="tt.description"
        src="assets/images/info.svg"
        alt="info"
        [matTooltip]="tt.description | capitalize"
        matTooltipClass="tooltip"
      />
    </div>
    <div class="gaspar-card scrollable" [class.disabled]="!tt.is_active">
      <div>
        <div class="card-container">
          <div class="header-container">
            <div class="header-tag-container flex row">
              <div
                class="header-tag-is-system"
                *ngIf="tt.is_system"
                i18n="tags: System"
              >
                System
              </div>
              <div class="header-tag-title">{{ tt.name }}</div>
              <div
                class="color-circle"
                [style.background-color]="tt.color"
              ></div>
            </div>
            <div class="toggle-container flex row">
              <mat-checkbox
                [checked]="tt.unique"
                class="toggle"
                disabled="true"
              >
                <span class="gaspar-span" i18n="tags: toggle is unique"
                  >Is unique?</span
                >
              </mat-checkbox>
              <mat-checkbox
                [checked]="tt.unique"
                disabled="true"
                [checked]="tt.user_selectable"
              >
                <span class="gaspar-span" i18n="tags: toggle is selectable"
                  >Is selectable?</span
                >
              </mat-checkbox>
            </div>
            <div class="icon-box pointer flex row" [matMenuTriggerFor]="menu">
              <fa-icon [icon]="ellipsisIcon" class="dots"></fa-icon>
              <mat-menu
                #menu="matMenu"
                backdropClass="gaspar-menu"
                [disabled]="!tt.is_active"
              >
                <button mat-menu-item>
                  <div class="item" (click)="onEdit(tt)">
                    <img src="assets/images/edit.svg" alt="edit" /> Edit
                  </div>
                </button>
                <button
                  mat-menu-item
                  class="item"
                  (click)="onArchive(tt)"
                  [disabled]="tt.is_system"
                  [class.disabled]="tt.is_system"
                >
                  <div class="item" [class.delete]="tt.is_active">
                    <!-- TODO: Single getter which determines the image to be used -->
                    <img
                      src="assets/images/delete-red.svg"
                      alt="archive"
                      *ngIf="tt.is_active"
                    />
                    <img
                      src="assets/images/unarchive.svg"
                      alt="archive"
                      *ngIf="!tt.is_active"
                    />
                    {{ tt.is_active ? "Archive" : "Unarchive" }}
                  </div>
                </button>
              </mat-menu>
            </div>
          </div>
          <div class="content-container">
            <div class="tags-length">{{ tagsLengthByType(tt) }}</div>
          </div>
          <div class="line"></div>
          <div class="tags-container" *ngIf="tt.is_active">
            <div class="tag-container" *ngFor="let tag of tagsByType(tt)">
              <div *ngIf="tag.is_active">
                <app-tag
                  [tag]="tag"
                  (tagClick)="editTagButtonClicked(tag)"
                  [showSlas]="true"
                ></app-tag>
              </div>
            </div>
            <div
              *ngIf="tt.can_add_tags"
              class="tag-container add-tag-button"
              [style.background-color]="tt.color"
              i18n="tags:add new tag button"
            >
              <div
                class="add-tag-button-background"
                (click)="addNewTagButtonClicked(tt)"
              ></div>
              Add new tag
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
