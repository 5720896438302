import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ChatService } from "../../shared/services/chat/chat.service";
import { ConversationsService } from "src/app/shared/services/conversations/conversations.service";
import { Subscription } from "rxjs";
import { WebSocketService } from "src/app/shared/services/websocket/websocket.service";
import { ArticleService } from "src/app/shared/services/article/article.service";
import { Article } from "src/app/shared/models/article";

interface Conversation {
  conversation_id: number;
}

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent implements OnInit, OnDestroy, AfterViewInit {
  private subscriptions: Subscription = new Subscription();
  public showButtons = false;
  messages: any[] = [];
  newMessage: string = "";
  conversationId: number;
  firstMessageSent: boolean = false;
  userId: number | null = null;
  typing: boolean = false;
  articleUrls: Map<string, string> = new Map();

  @ViewChild("scrollFrame", { static: false }) private scrollFrame: ElementRef;

  constructor(
    private chatService: ChatService,
    private conversationsService: ConversationsService,
    private route: ActivatedRoute,
    private wsService: WebSocketService,
    private articleService: ArticleService,
  ) {}

  ngOnInit() {
    this.subscriptions.add(
      this.route.params.subscribe(params => {
        const id = Number(params["conversationId"]);
        this.handleRouteParam(id);
      }),
    );

    this.subscriptions.add(
      this.wsService.messages.subscribe(message => {
        if (message && message.message) {
          this.messages.push(message);
          if (message.is_from_gaspar && message.status !== "processing") {
            this.typing = false;
          }
          if (message.articles && message.articles.length > 0) {
            message.articles.forEach((article: any) => {
              this.preloadArticleUrl(article);
            });
          }
        }
        setTimeout(() => this.scrollToBottom(), 0);
      }),
    );

    !this.conversationId && this.createConversation();
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.chatService.closeWebSocketConnection();
  }

  handleRouteParam(id: number) {
    if (!isNaN(id) && id > 0) {
      this.conversationId = id;
      this.loadConversationMessages(id);
    } else {
      this.initiateConversation();
    }
  }

  initiateConversation() {
    this.subscriptions.add(
      this.chatService
        .createConversation()
        .subscribe((conversation: Conversation) => {
          this.conversationId = conversation.conversation_id;
          this.firstMessageSent = false;
        }),
    );
  }

  scrollToBottom(): void {
    try {
      this.scrollFrame.nativeElement.scrollTo({
        top: this.scrollFrame.nativeElement.scrollHeight,
        behavior: "smooth",
      });
    } catch (err) {}
  }

  loadConversationMessages(conversationId: number) {
    this.subscriptions.add(
      this.conversationsService
        .getConversationMessages(conversationId)
        .subscribe(messages => {
          this.messages = messages.reverse();
          messages.forEach(message => {
            if (message.articles && message.articles.length > 0) {
              message.articles.forEach((article: any) => {
                this.articleUrls.set(article.title, article.url);
              });
            }
          });
        }),
    );
  }

  createConversation() {
    this.chatService.createConversation();
    this.subscriptions.add(
      this.chatService.getMessages().subscribe(message => {
        if (message && message.conversation_id) {
          this.conversationId = message.conversation_id;
          this.firstMessageSent = false;
        }
      }),
    );
  }

  sendMessage = async () => {
    this.typing = true;
    !this.conversationId && this.createConversation();

    if (this.newMessage.trim()) {
      if (!this.firstMessageSent) {
        this.chatService.updateConversationTitle(
          this.conversationId,
          this.newMessage,
        );
        this.firstMessageSent = true;
      }

      const customMessage = {
        type: "intentions",
        query: this.newMessage,
        conversation_id: this.conversationId,
      };
      this.chatService.sendMessage(this.conversationId, customMessage);
      this.newMessage = "";
    }
  };

  parseArticles(articlesJson: string): any[] {
    try {
      return JSON.parse(articlesJson);
    } catch (error) {
      return [];
    }
  }

  openArticle(articleTitle: string): void {
    const url = this.articleUrls.get(articleTitle);
    if (url) {
      window.open(url, "_blank");
    }
  }

  preloadArticleUrl(article: any): void {
    this.articleService.getArticle(article.title).subscribe({
      next: (preloadedArticle: Article) => {
        this.articleUrls.set(article.title, preloadedArticle.url);
      },
      error: () => {
        this.articleUrls.set(article.title, article.url);
      },
    });
  }

  isToday(dateValue: string | Date): boolean {
    const date = new Date(dateValue);
    const today = new Date();
    return date.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);
  }

  handleAction(messagePk: number, actionType: string): void {
    if (this.conversationId) {
      const actionMessage = {
        type: actionType,
        message_pk: messagePk,
        conversation_id: this.conversationId,
      };
      this.chatService.sendMessage(this.conversationId, actionMessage);
      this.updateMessageStatus(messagePk, actionType);
    }
  }

  updateMessageStatus(messagePk: number, status: string): void {
    const message = this.messages.find(m => m.id === messagePk);
    if (message) {
      message.button_clicked = status;
    }
  }

  extraButtons(buttons: any[]): any[] {
    return buttons.filter(
      button =>
        button.action !== "knowledge_confirmed" &&
        button.action !== "knowledge_declined",
    );
  }

  getSvgIcon(filename: string): string {
    const extension = filename.split(".").pop()?.toLowerCase();
    switch (extension) {
      case "pdf":
        return "assets/images/knowledge/pdf.svg";
      case "txt":
        return "assets/images/knowledge/txt.svg";
      case "docx":
        return "assets/images/knowledge/docx.svg";
      default:
        return "assets/images/knowledge/docx.svg";
    }
  }
}
