<mat-drawer-container>
  <mat-drawer
    #drawer
    [opened]="showSidebar"
    class="sidebar-container"
    mode="side"
  >
    <div class="sidebar-header">
      <span i18n="sidebar:admin portal title" class="sidebar-title">
        {{ "sidebar.admin portal title" | translate }}
      </span>
    </div>
    <div class="sidebar-content">
      <div class="sidebar-items">
        <p
          i18n="sidebar:Dashboard"
          class="sidebar-item"
          [class.selected]="currentTab === 'dashboard'"
          (click)="onSidebarTabChange('dashboard')"
        >
          <fa-icon [icon]="dashboardIcon" class="sidebar-icon"></fa-icon
          >{{ "sidebar.Dashboard" | translate }}
        </p>
        <p
          *ngIf="!isEmailAuth"
          i18n="sidebar:User management"
          class="sidebar-item"
          [class.selected]="currentTab === 'users'"
          (click)="onSidebarTabChange('users')"
        >
          <fa-icon [icon]="usersIcon" class="sidebar-icon"></fa-icon
          >{{ "sidebar.User management" | translate }}
        </p>
        <p
          *ngIf="!isEmailAuth"
          i18n="sidebar:Tags"
          class="sidebar-item"
          [class.selected]="currentTab === 'tags'"
          (click)="onSidebarTabChange('tags')"
        >
          <fa-icon [icon]="tagsIcon" class="sidebar-icon"></fa-icon
          >{{ "sidebar.Tags" | translate }}
        </p>
        <p
          *ngIf="!isEmailAuth"
          i18n="sidebar:Integrations"
          class="sidebar-item"
          [class.selected]="currentTab === 'integrations'"
          (click)="onSidebarTabChange('integrations')"
        >
          <img
            src="assets/images/sidebar/integration.svg"
            alt="integration"
            class="sidebar-icon"
          />{{ "sidebar.Integrations" | translate }}
        </p>
        <p
          *ngIf="!isEmailAuth"
          i18n="sidebar:Workflows"
          class="sidebar-item"
          [class.selected]="currentTab === 'workflows'"
          (click)="onSidebarTabChange('workflows')"
        >
          <img
            src="assets/images/sidebar/workflow.svg"
            alt="workflow"
            class="sidebar-icon"
          />{{ "sidebar.Workflows" | translate }}
        </p>
        <p
          i18n="sidebar:Knowledge"
          class="sidebar-item"
          [class.selected]="currentTab === 'knowledge'"
          (click)="onSidebarTabChange('knowledge')"
          *ngIf="hasBucket"
        >
          <img
            src="assets/images/knowledge/book.svg"
            alt="knowledge base"
            class="sidebar-icon"
          />{{ "sidebar.Knowledge" | translate }}
        </p>
        <img
          *ngIf="isChatEnabled"
          src="assets/images/line.svg"
          style="margin-bottom: 0.5rem"
        />
        <p
          *ngIf="isChatEnabled"
          i18n="sidebar:Chat"
          class="sidebar-item"
          [class.selected]="currentTab === 'chat'"
          (click)="onSidebarTabChange('chat')"
        >
          <img src="assets/images/sidebar/chat.svg" class="sidebar-icon" />{{
            "sidebar.Chat" | translate
          }}
        </p>
        <app-conversations *ngIf="currentTab === 'chat'"></app-conversations>
      </div>
    </div>
  </mat-drawer>
  <app-navbar
    [drawer]="drawer"
    [isSidebarAvailable]="isSidebarAvailable"
  ></app-navbar>
  <div class="main-container">
    <ng-content></ng-content>
  </div>
</mat-drawer-container>
